.header, .navlinks{
    font-family: "Montserrat";
    font-size: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.header{
    height: 4em;
    padding: 0 10%;
}

.header img{
    border-radius: 50%;
    margin-top: 5px;
}

.navlinks{
    gap: 2em;
}
.navlinks a{
    text-decoration: none;
    color: #000;
}
.navlinks a:hover{
    border: none;
    color: #00000089;
}

.btn-header{
    background-color: transparent;
    color: #000;
    border: none;
    display: none;
    cursor: pointer;
}

.menu-mobile{
    display: none;
    flex-direction: column;
}
.menu-mobile > ul{
    list-style: none;
    font-size: 20px;
}

@media screen and (max-width: 768px) {
    .btn-header{
        display: block;
    }
    .navlinks{
        justify-content: initial;
        z-index: 2;
        position: absolute;
        flex-direction: column;
        width: 40vw;
        height: 100vh;
        background-color: #a3a2a3;
        top: 0;
        margin-left: -100vw;
        padding: 2em;
        animation-duration: 1s;
    }
    @keyframes Sidebar {
        from{margin-left: -100vw;}
        top{margin-left: -10vw;}
    }

    @keyframes SidebarClose {
        from{margin-left: -10vw;}
        top{margin-left: -100vw;}
    }
}

@media screen and (width <= 768px) {
    .header{
        padding: 0 4%;
        border-bottom: 1px solid #000;
    }
    .header img{
        height: 55px;
        width: 55px;
    }
    .menu-mobile{
        display: flex;
        height: 60vh;
        top: 0;
        bottom: 0;
    }
    .menu-mobile > ul{
        text-align: center;
        margin: 0;
        padding: 0;
        gap: 1.4em;
        display: flex;
        flex-direction: column;
    }
    .menu-mobile > ul > li:hover{
        color: #000000a2;
    }
    .navlinks a:hover{
        border: none;
        color: #000000a2;
    }
}