footer{
    border-top: 1px solid #000;
    margin-top: 4em;
    display: flex;
    height: 220px;
    align-items: center;
    background-color: var(--color-silver);
    justify-content: space-evenly;
}

footer .card{
    width: 300px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.card p{
    margin: 0;
    font-size: 17px;
    text-align: justify;
}

.img-payments{
    width: 220px;
    height: 110px;
    margin-top: -20px;
    padding: 0;
}

footer a{
    font-size: 18px;
}

.icons-socials{
    background-color: #d5d5d58e;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.icons-socials .logo-img{
    border-radius: 50%;
    width: 65px;
    height: 60px;
}

.icons-socials p{
    margin: 0;
    font-size: 16px;
    text-align: center;
    font-weight: normal;
}

.icons-socials p:nth-child(2){
    font-size: 23px;
    font-weight: bold;
}

.icons-socials svg{
    font-size: 2.1em;
}

/* --- Grandes Telas --- */
@media screen and (min-width: 1024px) and (max-width: 1268px){
    footer{
        padding: 0 5%;
    }
    footer .card:nth-child(1){
        align-items: start;
    }
}


@media screen and (min-width: 668px) and (max-width: 1023px){
    footer{
        display: flex;
        padding: 0 2%;
        height: 250px;
        align-items: center;
    }
    footer .card:nth-child(1){
        align-items: start;
    }
    footer .card{
        width: 300px;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .card h3{
        font-size: 17px;
    }

    .card p{
        margin: 0;
        font-size: 15px;
        text-align: start;
    }
    .card .img-payments{
        width: 140px;
        height: 70px;
        margin-top: 0px;
    }
    footer a{
        font-size: 16px;
    }
    .icons-socials svg{
        font-size: 1.6em;
    }

    .icons-socials p{
        font-size: 14px;
    }
    
    .icons-socials p:nth-child(2){
        font-size: 20px;
    }

}

@media screen and (max-width: 500px) {
    footer{
        padding: 0;
        justify-content: center;
        display: grid;
        grid-template-columns: auto auto;
        height: 380px;
    }
    footer .card{
        width: 150px;
        align-items: start;
    }
    footer a{
        font-size: 16px;
    }
    .card h3{
        font-size: 16px;
    }
    .card p{
        font-size: 14px;
        text-align: left;
    }
    .img-payments{
        display: none;
    }
    .icons-socials svg{
        font-size: 1.2em;
    }
    .icons-socials p{
        font-size: 12px;
    }
    .icons-socials p:nth-child(2){
        font-size: 18px;
    }
    .icons-socials .logo-img{
        width: 45px;
        height: 40px;
    }
}