@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

:root{
  --color-silver: #d5d5d5;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat';
  overflow-x: hidden;
  box-sizing: border-box;
}

a{
  text-decoration: none;
  color: #000;
}